<template>
  <VueHtml2pdf
    ref="html2Pdf"
    class="pdf-converter"
    :show-layout="true"
    :floatLayout="floatLayout"
    :enableDownload="enableDownload"
    :previewModal="previewModal"
    :pdfQuality="pdfQuality"
    :manualPagination="manualPagination"
    :pdfContentWidth="pdfContentWidth"
    :pdfOrientation="pdfOrientation"
    :htmlToPdfOptions="htmlToPdfOptions"
    @beforeDownload="getArrayBuffer"
  >
    <section slot="pdf-content">
      <slot />
    </section>
  </VueHtml2pdf>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import ApiService from "@/services/ApiService";
import { downloadPdf } from "@/utils/pdfUtils";
import { ALERT_ACTION } from "@/store/modules/alert/action";
import apiPath from "@/services/apiPath";

export default {
  name: "PdfConverter",
  components: {
    VueHtml2pdf,
  },
  props: {
    apiPath: {
      type: String,
    },

    enableDownload: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
    },
    previewModal: {
      type: Boolean,
      default: false,
    },
    pdfQuality: {
      type: Number,
      default: 1,
    },
    pdfOrientation: {
      type: String,
      default: "portrait", //landscape
    },
    defaultWidth: {
      type: Number,
      default: 1280,
    },
    ignoreClassNameList: {
      type: Array,
      default: function () {
        return ["head_section", "section_sticky"];
      },
    },
  },
  data() {
    return {
      floatLayout: false,
      manualPagination: true,
      pdfContentWidth: "100%",
      htmlToPdfOptions: {
        enableLinks: false,
        html2canvas: {
          ignoreElements: this.ignoreElements,
        },
        pagebreak: { mode: ["avoid-all"], after: "#root" },
        margin: 0,
        jsPDF: {
          unit: "px",
          format: [],
        },
      },
      pdfSysFilePath: "",
      fileSize: "",
    };
  },
  computed: {
    computedApiPath() {
      let ret = `${this.$apiPath.PDF_HISTORY}`;
      if (this.apiPath) {
        ret = this.apiPath;
      }
      return ret;
    },
  },
  mounted() {},

  methods: {
    ignoreElements(el) {
      if (this.ignoreClassNameList.some((item) => el.classList.contains(item))) {
        return true;
      }
    },
    async getArrayBuffer($event) {
      //{ html2pdf, options, pdfContent }

      const { html2pdf, options, pdfContent } = $event;
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .output("arraybuffer")
        .then((arrayBuffer) => {
          const formData = this.setFromData(arrayBuffer);

          this.submitPdfData(formData);
        });
    },
    setFromData(arrayBuffer) {
      const formData = new FormData();
      const paramString = JSON.stringify(this.detailData);
      this.orgFile = this.makeOrgFile();

      formData.append("file", new Blob([arrayBuffer]), this.orgFile);

      formData.append("param", paramString);
      return formData;
    },
    async submitPdfData(formData) {
      if (!this.pdfSysFilePath) {
        //신규
        const config = {
          type: "multipart/form-data",
        };
        const result = await ApiService.shared.post(`${this.computedApiPath}`, formData, config); //알버트의 api

        const { code, data, text } = result;
        if (code === "200") {
          this.pdfSysFilePath = result.data.sysFilePath;
          this.$emit("sendPaymentCb", result);
        } else {
          alert(text);
        }
      } else {
        //edit && update
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
          text: "이미 전송되었습니다.",
        });
      }
    },

    async generateReport() {
      this.htmlToPdfOptions.jsPDF.format =
        this.pdfOrientation === "portrait"
          ? [this.defaultWidth, this.defaultWidth * 1.414]
          : [this.defaultWidth * 1.414, this.defaultWidth];
      await this.$refs.html2Pdf.generatePdf();
    },
    makeOrgFile() {
      return this.refPk ? `${this.refPk}.pdf` : "default.pdf";
    },
  },
};
</script>
<style lang="scss" scoped>
// .pdf-converter {
//   min-width: 1240px;
//   padding: 0 20px;
//   margin: 0 auto;
// }
</style>
