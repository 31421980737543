export function downloadPdf(blob, fileName) {
  const arr = new Uint8Array(blob);

  const newBlob = new Blob([arr], { type: "application/pdf" });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement("a");
  link.href = data;
  link.download = fileName || "download";
  link.click();
}

export function ObjectURLPdf(blob) {
  const arr = new Uint8Array(blob);
  const newBlob = new Blob([arr], { type: "application/pdf" });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }
  const url = window.URL.createObjectURL(newBlob);
  return url;
}
