<template>
  <DropdownBase>
    <el-dropdown :trigger="trigger" :placement="placement" @command="handleCommand">
      <template v-if="title">
        <el-button :class="buttonClass">
          {{ title }}
        </el-button>
      </template>
      <template v-else>
        <slot name="button"></slot>
      </template>

      <el-dropdown-menu slot="dropdown" :class="isColunmMenu">
        <template v-if="dataList.length > 0">
          <template v-if="maxRow === 0">
            <!--기본으로 쭉뿌릴때-->
            <el-dropdown-item
              v-for="(item, index) in dataList"
              :key="`${item.value}_${index}`"
              :command="item.value"
              :disabled="item.disabled"
              :divided="item.divided"
            >
              {{ item.name }}
            </el-dropdown-item>
          </template>
          <template v-esle>
            <!-- column으로 나눠서 뿌릴때 maxRow 값으로 배열을 쪼갬-->
            <li
              v-for="(dataList, index) in chunkArray"
              :key="`chunk_${dataList}_${index}`"
              class="el-dropdown-column"
            >
              <ul class="el-dropdown-column__item">
                <el-dropdown-item
                  v-for="(item, index) in dataList"
                  :key="`${item.value}_${index}`"
                  :command="item.value"
                  :disabled="item.disabled"
                  :divided="item.divided"
                  :data-ellip="item.dataEllip"
                >
                  {{ item.name }}
                </el-dropdown-item>
              </ul>
            </li>
          </template>
        </template>
        <template v-else>
          <el-dropdown-item>
            {{ "데이터가 없습니다." }}
          </el-dropdown-item>
        </template>
      </el-dropdown-menu>
    </el-dropdown>
  </DropdownBase>
</template>

<script>
import DropdownBase from "./DropdownBase";

export default {
  name: "Dropdown",
  components: {
    DropdownBase,
  },
  props: {
    title: String,
    buttonClass: {
      type: String,
      default: "btn_secondary btn_small",
    },
    placement: {
      type: String,
      default: "bottom-end",
    },
    dataList: Array,
    trigger: String,
    maxRow: {
      type: Number,
      default: 0, //0이면 다뿌림
    },
  },
  data() {
    return {};
  },
  computed: {
    isColunmMenu() {
      return this.maxRow > 0 ? "el-dropdown-menu--type-column" : null;
    },
    chunkArray() {
      return _.chunk(this.dataList, this.maxRow);
    },
  },
  methods: {
    handleCommand(command) {
      this.$emit("update:command", command);
    },
  },
};
</script>
<style lang="scss" scoped>
.el {
  &-button {
    border: none;
  }
}
</style>
