<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 class="tit_section">세금계산서관리</h2>
      </div>
      <div class="body_section">
        <TransactionReportListFilterBar
          ref="filterBar"
          :defaultDates="defaultDates"
          :defaultIsError="defaultIsError"
          :defaultKeywordInputText="defaultKeywordInputText"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultStatusId="defaultStatusId"
          :keywordDatas="keywordList"
          :statusList="statusList"
          @onClickSearch="onClickSearch"
          @onClickExcelBtn="onClickExcelBtn"
        />

        <TableHead>
          <template v-slot:headLeft>
            <div class="group_form">
              <button
                class="btn_tertiary btn_medium"
                :disabled="computedCheckedList.length === 0"
                @click="onClickSendAlarm"
              >
                <span class="material-icons-outlined send-alarm"> forward_to_inbox </span>세금계산서
                발행 요청
                <strong>{{ `(${computedCheckedList.length})` }}</strong>
              </button>
            </div>
          </template>
          <template v-slot:headRight>
            <div class="group_form">
              <span class="dropdown">
                <span
                  class="material-icons-outlined dropdown-info"
                  :data-ellip="'전자결재 - 승인상태가 승인일때 활성화됩니다.\n일괄승인 - 발행된 세금계산서가 정상일때 활성화됩니다.\n일괄 수정요청 - 발행된 세금계산서가 비정상일때 활성화됩니다.'"
                >
                  info
                </span>
                <Dropdown
                  trigger="click"
                  :dataList.sync="dropdownList"
                  :placement="'bottom-start'"
                  @update:command="onClickDropdown"
                >
                  <template v-slot:button>
                    <button class="btn_secondary btn_medium dropdown-btn">
                      일괄진행 {{ `(${computedCheckedList.length})` }}
                      <span class="material-icons more-vertical"> more_vert </span>
                    </button>
                  </template>
                </Dropdown>
              </span>
              <span class="bar_view"> </span>

              <SelectBoxPageRange
                :currentMaxRowCount.sync="size"
                @update:currentMaxRowCount="onChangeRowCount"
              />
            </div>
          </template>
        </TableHead>

        <PdfConverter
          ref="PdfConverter"
          :defaultWidth="pdfWidth"
          :apiPath="pdfApiPath"
          :ignoreClassNameList="['tr_notSelect', 'page_comm']"
          :detailData="detailData"
          @sendPaymentCb="sendPaymentCb"
        >
          <ListPage
            ref="transactionList"
            :currentPageIndex="currentPageIndex"
            :isHorizontalScroll="true"
            :list="dataList"
            :maxPaginationCount="maxPaginationCount"
            :maxRowCount="size"
            :scrollWidth="'2100px'"
            :totalPageCount="totalPageCount"
            @onChangePage="onChangePage"
          >
            <template v-slot:colgroup>
              <!-- 체크박스 -->
              <col style="width: 40px" />
              <!-- 발행상태 -->
              <col style="width: 80px" />
              <!-- 승인상태 -->
              <col style="width: 80px" />
              <!-- 거래명세서번호 -->
              <col style="width: 190px" />
              <!-- 발급일  -->
              <col style="width: 74px" />
              <!-- 검수번호 -->
              <col style="width: 160px" />
              <!-- 세금계산서번호 -->
              <col style="width: 190px" />
              <!-- 발주번호 -->
              <col style="width: 190px" />
              <!-- 발주명 -->
              <col style="width: 190px" />
              <!-- 프로젝트명 -->
              <col style="min-width: 190px" />
              <!-- 업체명 -->
              <col style="width: 80px" />
              <!-- 사업자번호 -->
              <col style="width: 120px" />
              <!-- 거래명세서금액 -->
              <col style="width: 120px" />
              <!-- 공급가액 -->
              <col style="width: 120px" />
              <!-- 세액 -->
              <col style="width: 120px" />
              <!-- 합계금액 -->
              <col style="width: 120px" />
              <!-- 전자결재 -->
              <col style="width: 60px" />
            </template>
            <template v-slot:tr>
              <th>
                <CheckboxItem ref="checkAll" @update:checkedNames="onClickedAll" />
              </th>
              <th>발행상태</th>
              <th>승인상태</th>
              <th>거래명세서번호</th>
              <th>발급일</th>
              <th>검수번호</th>
              <th>세금계산서번호</th>
              <th>발주번호</th>
              <th>발주명</th>
              <th>프로젝트명</th>
              <th>파트너사</th>
              <th>사업자번호</th>
              <th>거래명세서금액</th>
              <th>공급가액</th>
              <th>세액</th>
              <th>세금계산서금액</th>
              <th>전자결재</th>
            </template>
            <template v-slot:row="slotProps">
              <TransactionReportListLine
                :rowData="slotProps.rowData"
                :checkedNames.sync="checkedNames"
              />
            </template>
            <template v-slot:emptyList>
              <tr>
                <td class="td_empty" colspan="17">거래명세서 내역이 없습니다</td>
              </tr>
            </template>
          </ListPage>
        </PdfConverter>
      </div>
    </div>

    <template v-slot:popup>
      <AlertPopup
        v-if="isConfirmSubmit"
        alertText="승인 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseConfirmSubmit"
        @onClickPrimary="onClickOkConfirmSubmit"
      />
      <TransactionReportRejectPopup
        v-if="isConfirmReject"
        :disagreeReason.sync="disagreeReason"
        @onClickClose="onClickCloseConfirmReject"
        @onClickSubmit="onClickOkConfirmReject"
      />
      <AlertPopup
        v-if="isPaymentGo"
        alertText="전자결재문서가 등록되었습니다.<br />전자결재를 진행하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickClose"
        @onClickPrimary="onClickPlaymentGo"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import { mapState } from "vuex";
import ApiService from "@/services/ApiService";
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";
import ListPage from "@/components/shared/ListPage";
import { Dropdown } from "@/components/common/dropdown";
import PdfConverter from "@/components/shared/PdfConverter";
import AlertPopup from "@/components/layout/popup/AlertPopup";

import TransactionReportListFilterBar from "@/components/admin/transactionReport/list/TransactionReportListFilterBar.vue";
import TransactionReportListLine from "@/components/admin/transactionReport/list/TransactionReportListLine.vue";
import TransactionReportRejectPopup from "@/components/admin/transactionReport/popup/TransactionReportRejectPopup";
import PageMixin from "@/mixins/PageMixin";
import ListPageMixin from "@/mixins/ListPageMixin";
import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";
import { downloadFile } from "@/utils/fileUtils";
import { makeQueryStringByObject } from "@/utils/urlUtils";
import { ALERT_ACTION } from "@/store/modules/alert/action";
import { addCommaForMoney } from "@/utils/stringUtils.js";
import { TRANSACTIONREPORT_LIST_ACTION } from "@/store/modules/transactionreport/action";

export default {
  name: "TransactionReportList",
  components: {
    PageWithLayout,
    TableHead,
    SelectBoxPageRange,
    ListPage,
    Dropdown,
    PdfConverter,
    AlertPopup,

    CheckboxItem,
    TransactionReportListFilterBar,
    TransactionReportListLine,
    TransactionReportRejectPopup,
  },
  mixins: [PageMixin, ListPageMixin],
  props: {},
  data() {
    return {
      API_PATH: this.$apiPath.TRANSACTION,
      pdfApiPath: `${this.$apiPath.PDF_HISTORY}/httax`,
      defaultStatusId: "",

      dropdownList: [
        { name: "전자결재 진행", value: "sendListPayment", disabled: true },
        { name: "일괄 승인", value: "sendListAgree", disabled: true },
        { name: "일괄 수정요청", value: "sendListDisagree", disabled: true },
      ],
      defaultKeywordSelectedId: "projectName",
      defaultKeywordInputText: "",
      defaultIsError: false,
      pdfWidth: 1280,

      checkedNames: [],

      disagreeReason: "",
      isConfirmSubmit: false,
      isConfirmReject: false,
      isPaymentGo: false,
    };
  },
  computed: {
    ...mapState({
      statusList: (state) => state.transactionreport.statusList,
      keywordList: (state) => state.transactionreport.keywordList,
    }),
    computedInvoiceStatus() {
      return this.$route.query.issue;
    },

    computedCheckedList() {
      //여기서 전달데이터 가공
      const ret = this.checkedNames.map((item) => {
        return item.transactionReportCid;
      });
      return ret;
    },
    computedIsSendPayment() {
      let ret = false;
      if (this.checkedNames.length === 0) {
        ret = false;
      } else {
        const firstItem = this.checkedNames[0];
        const filterArray = this.checkedNames.filter((item) => {
          return item.agreeYn && (item.aprvlDocStatus === "" || item.aprvlDocStatus === "R");
        });
        ret = filterArray.length === this.checkedNames.length;
      }
      const dropdownItem = this.dropdownList.find((item) => item.value === "sendListPayment");
      dropdownItem.disabled = !ret;
      return !ret;
    },
    computedIsSendAgree() {
      //승인체크
      let ret = false;
      if (this.checkedNames.length === 0) {
        ret = false;
      } else {
        const filterArray = this.checkedNames.filter((item) => {
          //taxValidation === 'V' 세금계산서 일치 여부 추가
          return item.issue === "SUCCESS" && (item.agreeYn === false || item.agreeYn === "");
        });
        ret = filterArray.length === this.checkedNames.length;
      }
      const dropdownItem = this.dropdownList.find((item) => item.value === "sendListAgree");
      dropdownItem.disabled = !ret;
      return !ret;
    },
    computedIsSendDisgree() {
      //반려체크
      let ret = false;
      if (this.checkedNames.length === 0) {
        ret = false;
      } else {
        const filterArray = this.checkedNames.filter((item) => {
          return item.issue === "FAIL";
        });
        ret = filterArray.length === this.checkedNames.length;
      }
      const dropdownItem = this.dropdownList.find((item) => item.value === "sendListDisagree");
      dropdownItem.disabled = !ret;
      return !ret;
    },
    detailData() {
      if (this.checkedNames.length < 1) {
        return;
      }
      const ntsTotalAmount = this.checkedNames.reduce((acc, item) => {
        return acc + Number(item.ntsTotalAmount);
      }, 0);
      const projectNames = this.checkedNames.reduce((acc, item) => {
        acc = [...acc, item.projectName];
        return acc;
      }, []);
      const setProjectNames = [...new Set(projectNames)];
      let projectNameText = "";
      if (setProjectNames.length === 1) {
        projectNameText = `${setProjectNames[0]}`;
      } else {
        projectNameText = `${setProjectNames[0]} 외 ${setProjectNames.length - 1}건`;
      }
      const reqPath = this.$route.path;
      const reqTitle = `[${projectNameText}] 세금계산서금액 ${addCommaForMoney(
        ntsTotalAmount,
      )} (총${this.checkedNames.length}건)`; //this.itemData.title || '-';

      const refPk = this.computedCheckedList; //this.itemId;
      const menuId = reqPath.split("/")[1];
      let detailData = {
        refPk,
        reqPath,
        menuId,
        reqTitle,
      };
      return detailData;
    },
  },
  watch: {
    computedIsSendPayment(newVal, oldVal) {},
    computedIsSendAgree(newVal, oldVal) {},
    computedIsSendDisgree(newVal, oldVal) {},
    checkedNames(newVal, oldVal) {
      if (this.dataList.length === 0 || this.dataList.length !== newVal.length) {
        this.$refs.checkAll.forceUnChecked();
      } else {
        this.$refs.checkAll.forceChecked();
      }
    },
  },
  created() {
    const params = this.checkUrlQuery();
    this.$store.dispatch(TRANSACTIONREPORT_LIST_ACTION).then(() => {
      this.getDataInit(params);
    });
  },
  mounted() {
    this.pdfWidth = this.$refs.transactionList.$el.querySelector(".tbl_comm table").offsetWidth;
  },
  methods: {
    checkUrlQuery() {
      const { query } = this.$route;
      const objPaging = this.checkQueryPaging(query);
      const objKeyword = this.checkQueryKeywords(query, this.keywordList);
      const objIssue = this.checkQueryIssue(query);
      const objDates = this.checkQueryDates(query, "startDate", "endDate");
      const ret = Object.assign({}, objPaging, objKeyword, objDates, objIssue);
      const { isError } = query;

      if (isError) {
        this.defaultIsError = isError === "Y";
        ret.isError = isError;
      }
      return ret;
    },
    getFilterbarParams(objData, isForExcel) {
      const { keywordSelectedId, keywordInputText, startDt, endDt, status, isError } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }
      if (startDt && endDt) {
        params["startDate"] = startDt;
        params["endDate"] = endDt;
      }
      if (status) {
        params.issue = status;
      }

      // }
      if (isError) {
        params.isError = "Y";
      }

      return params;
    },
    checkQueryIssue(query) {
      const { issue } = query;

      if (!issue) return {};

      this.defaultStatusId = issue;

      return { issue: issue };
    },
    onClickDropdown(value) {
      switch (value) {
        case "sendListPayment":
          //전자결재
          this.onClickSendPayment();
          break;
        case "sendListAgree":
          //승인
          this.onClickSubmit();
          break;
        case "sendListDisagree":
          //반려
          this.onClickReject();
          break;

        default:
          break;
      }
    },
    onClickedAll(checked) {
      if (checked) {
        this.checkedNames = this.dataList;
      } else {
        this.checkedNames = [];
      }
    },
    onClickSendAlarm() {
      const params = {
        text: `총 ${this.computedCheckedList.length}건의 세금계산서발행요청 알림을 발송 하시겠습니까?`,
        onClickY: () => {
          this.sendAlarm();
        },
      };
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    async sendAlarm() {
      const obj = {};
      obj.manualType = "TAX_BILL_REQUEST";
      obj.targetPk = this.computedCheckedList;
      const result = await ApiService.shared.post(`${this.$apiPath.ALRIM}/sendManual`, obj);

      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.computedCheckedList = [];
    },
    onClickSendPayment() {
      const ntsTotalAmount = this.checkedNames.reduce((acc, item) => {
        return acc + Number(item.ntsTotalAmount);
      }, 0);
      const projectNames = this.checkedNames.reduce((acc, item) => {
        acc = [...acc, item.projectName];
        return acc;
      }, []);
      const setProjectNames = [...new Set(projectNames)];
      let projectNameText = "";
      if (setProjectNames.length === 1) {
        projectNameText = `${setProjectNames[0]}`;
      } else {
        projectNameText = `${setProjectNames[0]} 외 ${setProjectNames.length - 1}건`;
      }
      const params = {
        text: `${projectNameText}<br />세금계산서금액 ${addCommaForMoney(ntsTotalAmount)}<br />총 ${
          this.checkedNames.length
        }건의 전자결재를 진행하시겠습니까?`,
        onClickY: () => {
          this.$refs.PdfConverter.generateReport();
          //this.alert('전자결재보낼수 잇는 API 붙여야됨');
        },
      };
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    sendPaymentCb(result) {
      const { code, data, text } = result;
      if (code === "200") {
        const params = {
          text: `전자결재문서가 등록되었습니다.<br />결재문서를 상신하시겠습니까?`,
          onClickY: () => {
            this.$router.push({
              path: `${this.$paymentRouterPath.PAYMENT_DRAFT_TEMP}/${data.docNo}`,
            });
          },
        };
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
      }
    },
    // 승인 버튼
    onClickSubmit() {
      this.isConfirmSubmit = true;
    },
    // 승인 컨펌 > 닫기
    onClickCloseConfirmSubmit() {
      this.isConfirmSubmit = false;
    },
    // 승인 컨펌 > 확인
    onClickOkConfirmSubmit() {
      this.isConfirmSubmit = false;
      this.disagreeReason = "";
      this.agreeYn = true;

      this.transactionAgree();
    },

    // 반려 버튼
    onClickReject() {
      this.isConfirmReject = true;
    },
    // 반려 컨펌 > 닫기
    onClickCloseConfirmReject() {
      this.isConfirmReject = false;
    },
    // 반려 컨펌 > 승인
    onClickOkConfirmReject() {
      this.isConfirmReject = false;

      this.agreeYn = false;
      this.transactionAgree();
    },
    async transactionAgree() {
      const { disagreeReason, agreeYn } = this;
      const path = `${this.$apiPath.TRANSACTION}/agree`;
      const obj = {
        transactionReportCidList: [...this.computedCheckedList],
        disagreeReason,
        agreeYn,
      };
      const result = await ApiService.shared.putData(path, obj);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      if (this.agreeYn) {
        this.alert(`총 ${this.checkedNames.length}건이 승인 되었습니다.`);
      } else {
        this.alert(`총 ${this.checkedNames.length}건이 거절 되었습니다.`);
      }

      const params = this.checkUrlQuery();
      this.getDataInit(params);
      this.agreeYn = false;

      this.checkedNames = [];
      this.disagreeReason = "";
    },
    async onClickExcelBtn() {
      this.$store.dispatch(PROGRESS_START_ACTION);
      const path = `${this.$apiPath.EXCEL}/tax`;
      const filterBar = this.$refs.filterBar;
      if (!filterBar) {
        this.$store.dispatch(PROGRESS_END_ACTION);
        return;
      }
      const objFilterBarData = filterBar.getCommonFilterbarData();
      const params = this.getFilterbarParams(objFilterBarData, true);
      const result = await ApiService.shared.get(`${path}${makeQueryStringByObject(params)}`, {
        isNeedAuth: true,
        responseType: "arraybuffer",
      });

      this.$store.dispatch(PROGRESS_END_ACTION);

      downloadFile(result, `세금계산서관리_${this.moment().format("YYYYMMDD")}.xlsx`);
    },
  },
};
</script>

<style lang="scss" scoped>
.ellips-box {
  position: relative;
  &__area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.dropdown {
  display: inline-block;
  &-info {
    display: inline-block;
    width: auto;
    margin-right: 4px;
    vertical-align: middle;
  }
  &_comm {
    vertical-align: middle;
  }
}
.more-vertical {
  font-size: 16px;
  vertical-align: middle;
}
.send-payment,
.send-alarm,
.sync-invoice {
  margin-right: 8px;
  font-size: 16px;
  vertical-align: middle;
}
</style>
