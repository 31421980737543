<template>
  <FilterBarBase
    :excelBtn="$isAdmin ? true : false"
    @onClickSearch="onClickSearch"
    @onClickExcelBtn="onClickExcelBtn"
    @onClickReset="onClickReset"
  >
    <dl class="w152">
      <dt>발생상태</dt>
      <dd>
        <SelectBox class="w152" :dataList="statusList" :value.sync="statusId" />
      </dd>
      <dt>오류계산서</dt>
      <dd>
        <div class="group_form">
          <CheckboxOnlyOne id="3" :isSelected.sync="isError" text="오류계산서" />
        </div>
      </dd>
    </dl>
    <dl>
      <dt>계산서발행기간</dt>
      <dd>
        <DateRangeQuickSelect :dates.sync="dates" />
      </dd>
      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <SelectBox class="w208" :dataList="keywordDatas" :value.sync="keywordSelectedId" />
          <Input class="w425" :value.sync="keywordInputText" @onEnterKey="onClickSearch" />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import Input from "@/components/common/input/Input";
import CheckboxOnlyOne from "@/components/common/checkbox/CheckboxOnlyOne";
import SelectBox from "@/components/common/selectBox/SelectBox";

import DateRangeQuickSelect from "@/components/common/calendar/DateRangeQuickSelect";

import FilterBarMixin from "@/mixins/FilterBarMixin";

export default {
  name: "TransactionReportListFilterBar",
  components: {
    FilterBarBase,
    Input,
    CheckboxOnlyOne,
    SelectBox,
    DateRangeQuickSelect,
  },
  mixins: [FilterBarMixin],
  props: {
    defaultIsError: Boolean,
    excelBtn: Boolean,
  },
  data() {
    return {
      isError: this.defaultIsError,
    };
  },
  methods: {
    onClickSearch() {
      let obj = this.getCommonFilterbarData();

      obj.isError = this.isError;
      this.$emit("onClickSearch", obj);
    },
    onClickExcelBtn() {
      const isDatesSelected = this.dates && this.dates.length === 2;

      this.$emit("onClickExcelBtn", isDatesSelected);
    },
    onClickReset() {
      this.isError = false;
      this.onClickResetFilterBar();
    },
  },
};
</script>
