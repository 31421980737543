<template>
  <tr :class="{ tr_select: isSelected, tr_notSelect: !isSelected }">
    <td>
      <CheckboxItem :id="rowData" :checkedNames.sync="checkedNamesSync" />
    </td>
    <!-- 발행상태 -->
    <td>
      {{ rowData.publishYn }}
    </td>
    <!-- 승인상태 -->
    <td>
      {{ computedAgreeYnText | nullToDash }}
    </td>
    <!-- 거래명세서번호  -->
    <td>
      <router-link :to="toPath" class="link_subject align_center">
        {{ rowData.transactionReportCid }}
      </router-link>
    </td>
    <!-- 발급일  -->
    <td>
      {{ rowData.taxInvoiceDate ? moment(rowData.taxInvoiceDate).format("YYYY-MM-DD") : "-" }}
    </td>
    <!-- 검수번호 -->
    <td>
      <button
        type="button"
        class="link_subject align_center"
        :data-ellip="rowData.description || ''"
        @click="$windowOpen(`${$routerPath.INSPECT_VIEW}/${rowData.inspectNum}`)"
      >
        {{ rowData.inspectNum }}
      </button>
    </td>
    <!-- 세금계산서번호 -->
    <td :data-ellip="rowData.taxValidationName" :class="computedTaxValidation">
      {{ computedTaxInvoiceNo | nullToDash }}
    </td>
    <!-- 발주번호 -->
    <td class="align_left">
      <button
        type="button"
        class="link_subject align_center"
        :data-ellip="rowData.description || ''"
        @click="$windowOpen(`${$routerPath.ORDER_VIEW}/${rowData.orderCid}`)"
      >
        {{ rowData.orderCid }}
      </button>
    </td>
    <!-- 발주명 -->
    <td class="align_left" v-html="rowData.orderTitle"></td>
    <!-- 프로젝트명 -->
    <td class="align_left" v-html="rowData.projectName"></td>
    <!-- 파트너사 -->
    <td class="td_ellip align_left">
      <button
        type="button"
        class="link_subject"
        :data-ellip="rowData.companyName || ''"
        @click="$windowOpen(`${$routerPath.PARTNER_VIEW}/${rowData.partnerUserNum}`)"
      >
        {{ rowData.partnerCompanyName || "" }}
      </button>
    </td>
    <!-- 사업자등록번호 -->
    <td>{{ rowData.partnerCorporationNum }}</td>
    <!-- 거래명세서금액 -->
    <td class="align_right">{{ rowData.totalPrice | currency }}</td>
    <!-- 공급가액-->
    <td class="align_right">{{ rowData.ntsTotalSupplyPrice | currency | nullToDash }}</td>
    <!-- 세액-->
    <td class="align_right">{{ rowData.ntsTotalTax | currency | nullToDash }}</td>
    <!-- 세금계산서신고금액-->
    <td class="align_right">{{ rowData.ntsTotalAmount | currency | nullToDash }}</td>
    <!-- 전자결재-->
    <td>
      <template v-if="rowData.aprvlDocStatus">
        <button
          :class="['link_subject', 'align_center']"
          @click="$windowOpen(`${computedPaymentPath}/${rowData.aprvlDocNo}`)"
        >
          {{ rowData.aprvlDocStatusName }}
        </button>
        <!-- <button
          v-if="isMyPayment"
          :class="['link_subject', 'align_center']"
          @click="$windowOpen(`${computedPaymentPath}/${rowData.aprvlDocNo}`)"
        >
          {{ rowData.aprvlDocStatusName }}
        </button>
        <button v-else :class="['align_center']" @click="onClickPaymentReject">
          {{ rowData.aprvlDocStatusName }}
        </button> -->
      </template>
      <template v-else>
        {{ "-" }}
      </template>
    </td>
  </tr>
</template>

<script>
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";
import { getPaymentRouterPath } from "@/utils/paymentPathUtils";
import { makePathWithQuery } from "@/utils/urlUtils";
import LocalStorageManager from "@/LocalStorageManager";

export default {
  name: "TransactionReportListLine",
  components: {
    CheckboxItem,
    ListRow,
  },
  mixins: [ListLineMixin],
  props: {
    rowData: Object,
    checkedNames: Array,
  },
  data() {
    return {
      // suretyList: [],
    };
  },
  computed: {
    toPath() {
      const { transactionReportCid } = this.rowData;
      const path = makePathWithQuery(
        this.$routerPath.TRANSACTIONREPORT_VIEW,
        transactionReportCid,
        this.$route.query,
      );
      return path;
    },
    isSelected() {
      const ret = this.checkedNames.find(
        (item) => String(item.transactionReportCid) === String(this.rowData.transactionReportCid),
      );

      return !!ret;
    },
    checkedNamesSync: {
      get() {
        return this.checkedNames;
      },
      set(value) {
        this.$emit("update:checkedNames", value);
      },
    },
    computedTaxInvoiceNo() {
      let ret = "";
      if (this.rowData.taxInvoiceNo) {
        const first = this.rowData.taxInvoiceNo.slice(0, 8);
        const secend = this.rowData.taxInvoiceNo.slice(8, 16);
        const third = this.rowData.taxInvoiceNo.slice(16, 24);
        ret = `${first}-${secend}-${third}`;
      } else {
        ret = null;
      }
      return ret;
    },
    computedTaxValidation() {
      const { issue, taxValidationName } = this.rowData;
      let ret = "";
      if (issue === "FAIL") {
        ret = "is-nonvalid";
      }
      return ret;
    },
    computedAgreeYnText() {
      let ret = "";
      if (this.rowData.agreeYn === "") {
        ret = "대기";
      } else if (this.rowData.agreeYn === false) {
        ret = "수정요청";
      } else {
        ret = "승인";
      }
      return ret;
    },
    isMyPayment() {
      const { username } = LocalStorageManager.shared.getUserData();
      const { loginId } = this.rowData;
      return username === loginId;
    },
    computedPaymentPath() {
      const { aprvlDocStatus } = this.rowData;
      const ret = getPaymentRouterPath(aprvlDocStatus);
      return ret;
    },
  },
  methods: {
    onClickPaymentReject() {
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
        text: "해당 기안의 열람 권한이 없습니다.",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.is-valid {
  color: blue;
}
.is-nonvalid {
  color: red;
  font-weight: 500;
}
.is-unknown {
  color: #607d8b;
}
</style>
